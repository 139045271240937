<template>
  <div>
    <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
    <span v-else>
      <v-simple-table dense class="mt-5 mb-5">
        <template v-slot:default>
          <tbody>
            <tr v-if="!claim.approved && claim.rejectedDate">
              <th width="30%" class="text-right">
                Rejected Date:
              </th>
              <td width="70%">
                {{ claim.rejectedDate }}
              </td>
            </tr>
            <tr v-if="!claim.approved && claim.rejectedReason">
              <th width="30%" class="text-right">
                Rejected Reason:
              </th>
              <td width="70%">
                {{ claim.rejectedReason }}
              </td>
            </tr>
            <tr v-for="claimFieldValue in expandedClaimFieldValues" :key="claimFieldValue.id">
              <th width="30%" class="text-right">
                {{ claimFieldValue.claimField.name }}
              </th>
              <td width="70%">
                {{ claimFieldValue.valueFormatted }}
              </td>
            </tr>
            <tr v-for="(claimUpload, i) in expandedClaimUploads" :key="i + '-uploads'">
              <th width="30%" class="text-right">File {{ i + 1 }}</th>
              <td width="70%">
                <a :href="claimUpload.href" target="_blank">{{ claimUpload.originalFilename }}</a> ({{
                  claimUpload.contentType
                }})
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-data-table
        dense
        class="mt-5 mb-5"
        :calculate-widths="true"
        hide-default-footer
        :headers="headers"
        :items="expandedClaimProducts[claim.id]"
      >
        <template v-slot:item.calculatedTotalPayoutAmount="{ item }">
          {{ item.calculatedTotalPayoutAmount | toNumber(2, selectedProgram) }}
          {{ item.calculatedTotalPayoutAmountCurrency ? item.calculatedTotalPayoutAmountCurrency : "" }}
        </template>
      </v-data-table>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  name: "ClaimDetailTable",
  props: {
    filters: Object,
    showParticipant: {
      type: Boolean,
      default: false
    },
    claim: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    expanded: [],
    expandedClaimProducts: [],
    expandedClaimFieldValues: [],
    expandedClaimUploads: [],
    headers: [
      {
        value: "promotionProduct.product.productKey",
        text: "Product Number",
        sortable: true
      },
      {
        value: "promotionProduct.product.name",
        text: "Product Name",
        sortable: true
      },
      {
        value: "promotionProduct.product.productCategory.name",
        text: "Product Category",
        sortable: true
      },
      {
        value: "serialNumber",
        text: "Serial Number",
        sortable: true
      },
      {
        value: "quantity",
        text: "Quantity",
        sortable: true,
        align: "center"
      },
      {
        value: "calculatedTotalPayoutAmount",
        text: "Amount",
        sortable: true,
        align: "right"
      }
    ],
    loading: false,
    total: 0,
    options: {
      sortBy: ["createdDate"],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    }
  }),
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    },
    filters: {
      handler() {
        this.initialize();
      },
      deep: true
    },
    claim: {
      deep: true,
      handler() {
        this.initialize();
      }
    }
  },
  methods: {
    initialize() {
      this.loading = true;

      let filters = Object.assign({}, this.filters);
      filters.submitted = true;
      filters.approved = true;
      filters.closed = true;
      this.fetchClaimDetail(this.claim);
    },
    fetchClaimDetail(claim) {
      this.loading = true;
      return Promise.all([
        this.fetchClaimProducts(claim),
        this.fetchClaimFieldValues(claim),
        this.fetchClaimUploads(claim)
      ]).finally(() => {
        this.loading = false;
      });
    },
    fetchClaimProducts(claim) {
      if (claim.id in this.expandedClaimProducts) {
        return Promise.resolve();
      }
      return ApiService.get("/api/claimProducts/byClaimId/" + claim.id).then(({ data }) => {
        this.expandedClaimProducts[claim.id] = data.content;
      });
    },
    fetchClaimFieldValues(claim) {
      this.expandedClaimFieldValues = [];
      return ApiService.get("/api/claimFieldValues/byClaimId/" + claim.id + "?size=100").then(({ data }) => {
        let claimFieldValues = data.content;
        claimFieldValues.sort((a, b) => a.claimField.rank - b.claimField.rank);
        this.expandedClaimFieldValues = claimFieldValues;
      });
    },
    fetchClaimUploads(claim) {
      this.expandedClaimUploads = [];
      return ApiService.get("/api/claims/" + claim.id + "/uploads/").then(({ data }) => {
        this.expandedClaimUploads = data._embedded.uploads;
      });
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"])
  }
};
</script>
